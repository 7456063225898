
.pag-siz{
    width:30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white
}

.gap1{
    gap:"20px"
}
.footer-GH{
    position: relative;
    bottom: 10px;
    
    padding-bottom: 20px;
    border:1px solid red
  } 

.carousel .control-dots {
    margin: 78px 0 !important;
    margin-left: 180px !important;
    text-align: start !important;
  
}
.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    background: #fff !important;
    width: 10px !important;
    height: 10px !important;
  
}
.carousel .control-dots .dot.selected {
   background:#00609C !important;
    
}

/*** pagination***/

ul.pagination {
list-style: none;
    margin: 0;
    padding: 0;
    display: flex !important;
    justify-content: right !important;
    column-gap: 10px !important;
    color:red !important;
}